module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-d8fc9eb3a3/3/buildhome/.yarn/berry/cache/gatsby-remark-images-npm-7.14.0-a55a0fccfe-10c0.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":888,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-typography-virtual-039a4fad59/3/buildhome/.yarn/berry/cache/gatsby-plugin-typography-npm-5.14.0-c9f7019ac7-10c0.zip/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/typography.js"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-218bca5784/3/buildhome/.yarn/berry/cache/gatsby-plugin-catch-links-npm-5.14.0-3e7b33ed41-10c0.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-80a8da0a83/3/buildhome/.yarn/berry/cache/gatsby-plugin-google-gtag-npm-5.14.0-7fc489a55d-10c0.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZP08KYZV5E"],"gtagConfig":{"anonymize_ip":false},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-38d4a051dd/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
